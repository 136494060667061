import { template as template_19b3c24d6ad04dc58029102ef8b4e033 } from "@ember/template-compiler";
const WelcomeHeader = template_19b3c24d6ad04dc58029102ef8b4e033(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
