import { template as template_b857d68ff3d34f26a29d31244aed8dd8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_b857d68ff3d34f26a29d31244aed8dd8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
