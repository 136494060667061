import { template as template_58a96c0a0dcf4a088f83e21498174522 } from "@ember/template-compiler";
const FKLabel = template_58a96c0a0dcf4a088f83e21498174522(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
